import { isNullOrUndef } from 'utils';

const addWebFonts = (fontData, frameName) => {
  if (isNullOrUndef(fontData) || typeof fontData !== 'object') {
    return false;
  }

  const fontInactive = (fontMsg) => {
    //FIXME: do we want to log on the api when a bad font fails to load?
    //eslint-disable-next-line
    console.log('addWebFonts fontInactive()', fontMsg);
  };

  const context = frameName && window.frames[frameName] ? window.frames[frameName] : window;
  const fontConfig = Object.assign({ fontinactive: fontInactive },
    fontData, frameName ? { context } : {});

  return import(/* webpackChunkName: "csw-vendor-webfontloader" */ 'webfontloader')
    .then(module => {
      const WebFont = module.default;
      try {
        WebFont.load(fontConfig);
      } catch (e) {
        // Ignoring errors.
      }
      return true;
    });
};

export default addWebFonts;

import { isNullOrUndefOrEmpty } from 'utils';

// @param {Element} domNode - node to test against.
//    Example: HTMLDocument, Element, HTMLElement
// @param {string} cssSelector - selectors to test against.
//    Example: 'span.storyimage,div.videoplayer,div.inline-slideshow,div.wcvideoplayer'
const containsSelector = (domNode, cssSelector) => {
  const isDomNode = (domNode instanceof HTMLElement) || (domNode instanceof Element)
    || (domNode instanceof HTMLDocument);
  if (!isDomNode || isNullOrUndefOrEmpty(cssSelector)) {
    return false;
  }
  return domNode.querySelector(cssSelector) !== null;
};

export default containsSelector;
